import React, {useEffect} from "react";
import {Routes, Route} from "react-router-dom";

import './App.css';
import './index.css';

//theme
import {ThemeProvider, createTheme} from '@mui/material/styles';
import Cookies from "js-cookie";
import Layout from "./Screens/Layout";

const Authentication = React.lazy(() => import("./Screens/Authentication"));
const ForgotPassword = React.lazy(() => import("./Screens/ForgotPassword"));
const CreatePassword = React.lazy(() => import("./Screens/CreatePassword"));
const LegalMentions  = React.lazy(() => import("./Screens/LegalMentions"));
const App = () => {
    //theme
    const [mode, setMode] = React.useState('dark');

    useEffect(() => {
        if (Cookies.get('theme')) {
            setMode(Cookies.get('theme'));
        } else {
            setMode('dark'); // thème par défaut
        }
        if (document.body.classList.contains('dark-theme')) {
            document.body.classList.remove('dark-theme');
        } else {
            document.body.classList.remove('light-theme');
        }
        if (Cookies.get('theme') === 'dark') {
            document.body.classList.add('dark-theme');
        } else {
            document.body.classList.add('light-theme');
        }
    }, []);

    const theme = React.useMemo(
        () =>
            createTheme({
                components: {
                    MuiButton: {
                        styleOverrides: {
                            root: {
                                color: 'inherit', // Hérite de la couleur du parent
                                backgroundColor: 'transparent', // Fond transparent
                                boxShadow: 'none', // Pas d'ombre
                                '&:hover': {
                                    backgroundColor: 'transparent', // Fond transparent au survol
                                    boxShadow: 'none', // Pas d'ombre au survol
                                },
                                '&:active': {
                                    boxShadow: 'none', // Pas d'ombre en état actif
                                },
                                '&:focus': {
                                    boxShadow: 'none', // Pas d'ombre en état focus
                                },
                            },
                        },
                    },
                },
                palette: {
                    mode,
                },
                accordion: {
                    backgroundImage: 'none',
                },
                accordionSummary: {
                    backgroundImage: 'none',
                },
                typography: {
                    fontFamily: 'var(--font-family-app)', // Définit la font par défaut pour tous les composants MATERIAL UI
                },

            }),
        [mode],
    );
    //theme

    React.useEffect(() => {
        // 👇 add class to body element
        if (document.body.classList.contains('dark-theme')) {
            document.body.classList.remove('dark-theme');
        } else {
            document.body.classList.remove('light-theme');
        }
        if (theme.palette.mode === 'dark') {
            document.body.classList.add('dark-theme');
        } else {
            document.body.classList.add('light-theme');
        }
    }, [theme]);

    return (
        <ThemeProvider theme={theme}>
            <Layout>
                <Routes>
                    <Route path="/">
                        <Route index
                               element={
                                   <React.Suspense fallback={<>...</>}>
                                       <Authentication/>
                                   </React.Suspense>
                               }/>
                        <Route
                            path="/reset-mot-de-passe"
                            element={
                                <React.Suspense fallback={<>...</>}>
                                    <ForgotPassword/>
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/password-set/:token"
                            element={
                                <React.Suspense fallback={<>...</>}>
                                    <CreatePassword/>
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/mentions-legales"
                            element={
                                <React.Suspense fallback={<>...</>}>
                                    <LegalMentions/>
                                </React.Suspense>
                            }
                        />
                    </Route>
                </Routes>
            </Layout>
        </ThemeProvider>
    );
}

export default App;
